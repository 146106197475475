import React, { useEffect, useState } from "react";
import { H2 } from "../reusable/Heading/index";
import Button from "@mui/material/Button";
import Select from "react-dropdown-select";
import TextField from '@mui/material/TextField';
import { Grid, Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';


function QuestionTree({next, handleQuestionnaire}) {
  const questions = {
    q1: {
      id: 1,
      q: "What Channel is your Store?",
      answer_options: [
        "Accommodation",
        "Automotive",
        "Beauty Specialists",
        "Consumer Electronics",
        "Convenience",
        "Department Stores",
        "Discount",
        "Fashion and Apparel",
        "Food Service",
        "Home Specialists",
        "Hyper-Stores",
        "Leisure/Entertainment",
        "Other Food Specialists",
        "Other Non-Food Specialists",
        "Other Non-Retail",
        "Pharma & Health",
        "Supermarkets & Neighbourhood Stores",
      ],
    },
    q2: {
      id: 2,
      q: "What Type of Store do you have?",
      answer_options: {
        a1: [
          "Specialty Electronics Store",
          "Full-Range Electronics Store",
          "Gaming Store",
        ],
        a2: ["Convenience Store", "Gas Station Convenience Store"],
        a3: ["Standard Department Store", "Discount Department Store"],
        a4: ["Non-Food Discount Store", "Food Discount Store"],
        a5: ["Apparel Store", "Jewelry & Accessories Store", "Footwear Store"],
        a6: [
          "Quick Service Restaurant",
          "Restaurant/Bar/Café",
          "Confectionery",
          "Fast Food Street Kiosk",
        ],
        a7: ["DIY Store", "Home Furnishing Store", "Garden Centre"],
        a8: ["Hypermarkets", "SuperStore"],
        a9: [
          "Books/Music/Video Store",
          "Sporting Goods Store",
          "Outdoor Pursuits",
          "Hobbies & Crafts Store",
          "Toy Store",
          "Cinema",
          "Night Club",
        ],
        a10: [
          "Food Production Store",
          "Specialist Food Store",
          "Liquor/Beverage Store",
        ],
        a11: [
          "Parent & Baby Store",
          "Automotive Store",
          "Gift/Novelty Store",
          "Tobacconist",
          "Other Non-Food Store",
          "Pet Store",
          "Office Store",
        ],
        a12: ["DrugStore/Pharmacy", "Optician"],
        a13: ["Supermarket", "Neighbourhood Store"],
      },
    },
    q3: {
      id: 3,
      q: "How many ROOMS does this outlet have?",
      answer_options: [" "],
      next: "q4",
    },
    q4: {
      id: 4,
      q: "Does this outlet have an ALCOHOL license?",
      answer_options: ["Yes", "No"],
      next: "q5",
    },
    q5: {
      id: 5,
      q: "Is this outlet HALAL ONLY?",
      answer_options: ["Yes", "No"],
      next: "q6",
    },
    q6: {
      id: 6,
      q: "How many CONSULTANT DESKS does this outlet have?",
      answer_options: [" "],
    },
    q7: {
      id: 7,
      q: "How many TILLS does this outlet have?",
      answer_options: [
        "1 x Till (Kiosk)",
        "2-3 Tills (Convenience)",
        "4-5 Tills (Small outlet)",
        "6-20 Tills (Medium outlet)",
        "21+ Tills (Large outlet)",
        "No Tills (Digital Payments)",
      ],
    },
    q8: {
      id: 8,
      q: "Which FOOD TO GO categories does this outlet sell?",
      answer_options: [
        "Food To Go Chilled (Sandwiches Salads etc. Sold from chilled space)",
        "Food To Go Hot (Pizza Burgers Sausage rolls etc. Sold from a hot counter)",
        "Hot Drinks",
        "None",
      ],
    },
    q9: {
      id: 9,
      q: "Does this outlet have a POST OFFICE and a PHARMACY?",
      answer_options: ["Pharmacy", "Post Office", "None"],
    },
    q10: {
      id: 10,
      q: "Which FOOD & DRINK categories does this outlet sell?",
      answer_options: [
        "Biscuits (Sweet & Savoury inc. Crackers)",
        "Bread & Cakes",
        "Confectionery",
        "Crisps & Snacks (inc. Nuts)",
        "Fresh (Food sold from chilled space: Dairy / Milk / Cooked Meats / Microwave Meals)",
        "Frozen",
        "Packaged Grocery (Boxed or Tinned)",
        "Soft Drinks",
        "Meat Fish & Poultry (Raw/Uncooked)",
        "Produce (Fruit & Vegetables)",
        "None",
      ],
    },
    q11: {
      id: 11,
      q: "Which LEISURE categories does this outlet sell?",
      answer_options: [
        "Books",
        "Newspapers and Magazines",
        "Tobacco",
        "Toys & Games",
        "Vaping",
        "None",
      ],
    },
    q12: {
      id: 12,
      q: "Which of the following CATEGORIES does this outlet sell?",
      answer_options: [
        "Clothing",
        "Electrical",
        "Health & Beauty",
        "Homeware",
        "Stationery",
        "None",
      ],
    },
    q13: {
      id: 13,
      q: "How many COVERS does this outlet have?",
      answer_options: [" "],
    },
    q14: {
      id: 14,
      q: "Does this outlet have a POST OFFICE?",
      answer_options: ["Post Office", "None"],
    },
    q15: {
      id: 15,
      q: "How many SCREENS does this outlet have?",
      answer_options: [" "],
    },
    q16: {
      id: 16,
      q: "What is the CAPACITY of this venue?",
      answer_options: [" "],
    },
    q17: {
      id: 17,
      q: "Instore Vet",
      answer_options: ["Yes", "No"],
    },
    q18: {
      id: 18,
      q: "How many CONSULTING ROOMS does this outlet have?",
      answer_options: [" "],
    },
    q19: {
      id: 19,
      q: "Does this outlet sell HEALTH & BEAUTY products?",
      answer_options: ["Yes", "No"],
    },
  };

  function getPath(a1) {
    let path;
    let ansOpt;
    switch (a1) {
      case "Accommodation":
        path = ["q3", "q4", "q5", "q8"];
        return path;
      case "Automotive":
        path = ["q6"];
        return path;
      case "Beauty Specialists":
        path = ["q7"];
        return path;
      case "Consumer Electronics":
        path = ["q2", "q7"];
        ansOpt = "a1";
        return [path, ansOpt];
      case "Convenience":
        path = ["q2", "q7", "q4", "q5", "q8", "q9", "q10", "q11", "q12"];
        ansOpt = "a2";
        return [path, ansOpt];
      case "Department Stores":
        path = ["q2", "q7", "q4", "q5", "q8", "q9", "q10", "q11", "q12"];
        ansOpt = "a3";
        return [path, ansOpt];
      case "Discount":
        path = ["q2", "q7"];
        ansOpt = "a4";
        return [path, ansOpt];
      case "Food Discount Store": // IF q2 getPath and if not path === getPath, path = getPath
        path = ["q7", "q4", "q5"];
        return path;
      case "Fashion and Apparel":
        path = ["q2", "q7"];
        ansOpt = "a5";
        return [path, ansOpt];
      case "Food Service":
        path = ["q2", "q7", "q4", "q5", "q8"];
        ansOpt = "a6";
        return [path, ansOpt];
      case "Restaurant/Bar/Café":
        path = ["q13", "q4", "q5", "q8"];
        return path;
      case "Home Specialists":
        path = ["q2", "q7"];
        ansOpt = "a7";
        return [path, ansOpt];
      case "Hyper-Stores":
        path = ["q2", "q7", "q4", "q5", "q8", "q9", "q10", "q11", "q12"];
        ansOpt = "a8";
        return [path, ansOpt];
      case "Leisure/Entertainment":
        path = ["q2", "q7"];
        ansOpt = "a9";
        return [path, ansOpt];
      case "Cinema":
        path = ["q15", "q4", "q5"];
        return path;
      case "Night Club":
        path = ["q16", "q4", "q5"];
        return path;
      case "Other Food Specialists":
        path = ["q2", "q7", "q4", "q5", "q8", "q10"];
        ansOpt = "a10";
        return [path, ansOpt];
      case "Other Non-Food Specialists":
        path = ["q2", "q7"];
        ansOpt = "a11";
        return [path, ansOpt];
      case "Other Non-Retail":
        path = ["q7", "q4", "q5"];
        return path;
      case "Pet Store":
        path = ["q7", "q17"];
        return path;
      case "Pharma & Health":
        path = ["q2", "q7", "q19"];
        ansOpt = "a12";
        return [path, ansOpt];
      case "Optician":
        path = ["q18", "q19"];
        return path;
      case "Supermarkets & Neighbourhood Stores":
        path = ["q2", "q7", "q4", "q5", "q8", "q9", "q10", "q11", "q12"];
        ansOpt = "a13";
        return [path, ansOpt];
      default:
        return null;
    }
  }

  const [currentQ, setCurrentQ] = useState(questions.q1);
  const [prevQ, setPrevQ] = useState(null);
  const [nextQ, setNextQ] = useState(null);
  const [path, setPath] = useState([]);
  const [answer, setAnswer] = useState(null);
  const [index, setIndex] = useState(0);
  const [qTwoOptions, setQTwoOptions] = useState(null);
  const [disabled, setDisabled] = useState(true)

  function handleClickNext() {
    if (currentQ.q === questions.q1.q || currentQ.q === questions.q2.q) {
      if (currentQ.q === questions.q1.q) {
        const get_path = getPath(answer)
        if (Array.isArray(get_path[0])) {
          setPath(get_path[0]);
          setQTwoOptions(get_path[1])
        } else {
          setPath(getPath(answer));
        };
      } 
    }
    setPrevQ(currentQ);
    setCurrentQ(nextQ);
    setDisabled(true);
    return;
  }

  function handleClickPrev() {
    setNextQ(currentQ);
    setCurrentQ(prevQ);
    if (currentQ.q === questions.q1.q) {
      setPrevQ(null);
    } else {
      setPrevQ(path[index - 2]);
    };
    setIndex(index-1)
  }

  useEffect(() => {
    if (prevQ) {
      handleQuestionnaire({ id: prevQ.id, q: prevQ.q, a: answer})
    };
    setIndex(index+1);
    setNextQ(questions[path[index]]);
  }, [currentQ]);

  function createAnswerOptions(answer_options) {
    const options = [];
    if (currentQ.q === questions.q2.q ) { 
      answer_options = questions.q2.answer_options[qTwoOptions]
    }
    answer_options.forEach((answer) =>
      options.push({ label: answer, value: answer })
    );
    return options;
  }

  function handleFirstSelectChange(value) {
    setAnswer(value);
    setNextQ(questions[getPath(value)[0]]);
    if (Array.isArray(getPath(value)[0])) {
      setNextQ(questions[getPath(value)[0][0]]);
    }
    setDisabled(false);
  };

  function handleSelectChange(value) {
    if (value.length === 1) {
      setAnswer(value[0].value);
    } else {
      const values = []
      value.forEach((v) =>
      values.push(v.value)
      );
      setAnswer(values);
    }
    setDisabled(false);
    if (currentQ.q === questions.q2.q) {
      const checkQ2 = getPath(value);
      if (checkQ2 !== null) {
        setIndex(0);
        setPath(checkQ2);
      };
    }
  };

  useEffect (() => {
    setNextQ(questions[path[index]])
    setIndex(index+1)
  }, [path])

  function handleTextChange(value) {
    setAnswer(value);
    setDisabled(false);
  };

  function handleClickSubmit() {
    handleQuestionnaire({ id: currentQ.id, q: currentQ.q, a: answer});
    next();
  };

  return (
    <>
      { currentQ !== undefined && currentQ.q === questions.q1.q && (
        <div>
          {" "}
          <Typography>{currentQ.q}</Typography>
          <Box m={3}></Box>
          <Grid>
            <Select
              options={createAnswerOptions(currentQ.answer_options)}
              values={[]}
              onChange={(value) => handleFirstSelectChange(value[0].value) }
            />
          </Grid>
          <Box m={3}></Box>
          <Button
            style={{ float: 'center'}} color="primary"
            onClick={() => handleClickNext()}
            variant="outlined"
            className="outlined-button"
            disabled={disabled}
          >
            Next
          </Button>
        </div>
      )}
      { currentQ !== undefined && currentQ.q !== questions.q1.q &&  (
        <div>
        {" "}
        <Typography>{currentQ.q}</Typography>
        <Box m={3}></Box>
        { currentQ.answer_options[0] === " " && (
          <Grid>
              <TextField 
                id="outlined-basic" 
                variant="outlined" 
                onChange={(event) => handleTextChange(event.target.value)}
                style={{
                  padding: "4px 5px 5px !important",
                  width: "100%"
                }}
              />
            <Box m={3}></Box>
          </Grid>
        )}
        { currentQ.answer_options[0] !== " " && (
          <Grid>
            <Select
              options={createAnswerOptions(currentQ.answer_options)}
              values={[]}
              onChange={(value) => handleSelectChange(value)}
              multi={[9, 10, 8, 11, 12].includes(currentQ.id) ? true : false}
              color={"#FFAB00"}
            />
            <Box m={3}></Box>
          </Grid>
        )}
        <Button
          style={{ float: 'center', marginRight: '5px' }} color="primary"
          onClick={() => handleClickPrev()}
          variant="outlined"
          className="outlined-button"
        >
          Back
        </Button>
        { nextQ && (
        <Button
          style={{ float: 'center' }} color="primary"
          onClick={() => handleClickNext()}
          variant="outlined"
          className="outlined-button"
          disabled={disabled}
        >
          Next
        </Button>
        )}
        { nextQ === undefined && (
        <Button
          style={{ float: 'center' }} color="primary"
          onClick={() => 
            handleClickSubmit()
          }
          variant="outlined"
          className="outlined-button"
          disabled={disabled}
        >
          Submit
        </Button>
        )}
      </div>
      )}
    </>
  );
}

export default QuestionTree;
