export const baseUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000/"
    : "https://admin.smartspotter.com/";

const defaultOptions = (method, body) => {
  return {
    method: method,
    credentials: "include",
    headers: {
      "Access-Control-Allow-Origin": baseUrl,
      "Acces-Control-Allow-Credentials": "true",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
};

export const API = {
  signIn: async (values) => {
    const res = await fetch(
      baseUrl + "auth/spotters/sign_in",
      defaultOptions("POST", values)
    );
    return res.json();
  },
  signUpOwner: async (values) => {
    const res = await fetch(
      baseUrl + "v1/smartconnect/signup",
      defaultOptions("POST", values)
    );
    return await res.json();
  },
  checkEmail: async (values) => {
    const res = await fetch(
      baseUrl + "v1/spotters/check_if_email_exists",
      defaultOptions("POST", values)
    );
    return await res.json();
  },
  signUpBusiness: async (values) => {
    const res = await fetch(
      baseUrl + "v1/smartconnect/signup",
      defaultOptions("POST", values)
    );
    return res.json();
  },
  signOut: async (body = {}) => {
    const response = await fetch(
      baseUrl + "auth/spotters/sign_out",
      defaultOptions("POST", body)
    );
    return response.json();
  },
  loadProfile: async () => {
    const response = await fetch(
      baseUrl + "v1/spotters/profile",
      defaultOptions("GET")
    );
    return response.json();
  },
  resetPassword: async (body = {}) => {
    const response = await fetch(
      baseUrl + "v1/spotters/request_password_reset",
      defaultOptions("POST", body)
    );
    return response.json();
  },
  loadBusiness: async (body = {}) => {
    const response = await fetch(
      baseUrl + `v1/businesses`,
      defaultOptions("GET")
    );
    return response.json();
  },
  loadBusinessEmployees: async () => {
    const response = await fetch(
      baseUrl + `v1/business_employees`,
      defaultOptions("GET")
    );
    return response.json();
  },
  loadBusinessData: async () => {
    const response = await fetch(
      baseUrl + `v1/business_data`,
      defaultOptions("GET")
    );
    return response.json();
  },
  loadLocationForBusiness: async (businessId) => {
    const response = await fetch(
      baseUrl + `v1/businesses/${businessId}/location`,
      defaultOptions("GET")
    );
    return response.json();
  },
  addEmployeeToBusiness: async (body) => {
    const response = await fetch(
      baseUrl + `v1/employees/new`,
      defaultOptions("POST", body)
    );
    return response.json();
  },
  removeEmployeeFromBusiness: async (body) => {
    const response = await fetch(
      baseUrl + `v1/employees/remove/${body.id}`,
      defaultOptions("POST", body)
    );
    return response.json();
  },
  updateBusiness: async (body = {}, id) => {
    try {
      const response = await fetch(
        baseUrl + `v1/businesses/${id}`,
        defaultOptions("PATCH", body)
      );
      return response.json();
    } catch (error) {
      console.error("Business update error: ", error);
    }
  },
  fetchInvoices: async (id) => {
    try {
      const response = await fetch(
        baseUrl + `/v1/spotters/invoices`,
        defaultOptions("GET")
      );
      return response.json();
    } catch (error) {
      console.error("Invoice fetch error: ", error);
    }
  },
  addLocationToBusiness: async (body) => {
    const response = await fetch(
      baseUrl + `v1/businesses/${body.businessId}/add_location`,
      defaultOptions("POST", { business: body })
    );
    return response.json();
  },
  removeLocationFromBusiness: async (body) => {
    const response = await fetch(
      baseUrl + `v1/businesses/${body.businessId}/remove_location/`,
      defaultOptions("POST", { business: body })
    );
    return response.json();
  },
  addQuestionnaireToBusiness: async (values) => {
    const res = await fetch(
      baseUrl + "v1/smartconnect/signup_questionnaire",
      defaultOptions("POST", values)
    );
    return await res.json();
  },
  updateBillingInformation: async (values) => {
    const res = await fetch(
      baseUrl + "v1/spotters/update_billing_information",
      defaultOptions("POST", values)
    );
    return await res.json();
  },
  addBusinessToSpotter: async (values) => {
    const res = await fetch(
      baseUrl + "v1/spotters/add_business",
      defaultOptions("POST", values)
    );
    return await res.json();
  },
};
