import * as React from "react";
import Box from "@mui/material/Box";
import {
  Avatar,
  Menu,
  ListItemIcon,
  MenuItem,
  IconButton,
  Divider,
} from "@mui/material";
import { PersonAdd, Settings, Logout } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate, createSearchParams } from "react-router-dom";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { H5 } from "../Heading/index";
import { color } from "../../../theme";
import Cookies from "js-cookie";
import t from '../../../i18n';


export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const handleSignOut = async (e) => {
    e.preventDefault();
    Cookies.remove("spToken");
    window.location.reload();
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          disableRipple={true}
          disableFocusRipple={true}
        >
          <MenuIcon
            style={{
              marginRight: "0px",
              fill: `${color}`,
              fontSize: "30",
              marginBottom: "15px",
            }}
          />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            left: "695px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 10,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => navigate("/")}
          style={{ width: "100%", justifyContent: "flex-start" }}
        >
          <Avatar /> <H5>{t("profile")}</H5>
        </MenuItem>
        <Divider style={{ borderStyle: "none" }} />
        <MenuItem
          onClick={() =>
            navigate({
              pathname: "edit",
              search: createSearchParams({
                personal: "true",
              }).toString(),
            })
          }
          style={{ width: "100%", justifyContent: "flex-start" }}
        >
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          <H5>{t("edit_personal")}</H5>
        </MenuItem>
        <Divider style={{ borderStyle: "none" }} />
        <MenuItem
          onClick={() =>
            navigate({
              pathname: "edit",
              search: createSearchParams({
                business: "true",
              }).toString(),
            })
          }
          style={{ width: "100%", justifyContent: "flex-start" }}
        >
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          <H5>{t("edit_business")}</H5>
        </MenuItem>
        <Divider style={{ borderStyle: "none" }} />
        <MenuItem
          onClick={() =>
            navigate({
              pathname: "edit",
              search: createSearchParams({
                employee: "true",
              }).toString(),
            })
          }
          style={{ width: "100%", justifyContent: "flex-start" }}
        >
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          <H5>{t("add_new_user")}</H5>
        </MenuItem>
        <Divider style={{ borderStyle: "none" }} />
        <MenuItem
          onClick={() => navigate("/invoices")}
          style={{ width: "100%", justifyContent: "flex-start" }}
        >
          <ListItemIcon>
            <AccountBalanceIcon fontSize="small" />
          </ListItemIcon>
          <H5>{t("invoices")}</H5>
        </MenuItem>
        <Divider style={{ borderStyle: "none" }} />
        <MenuItem
          onClick={(e) => handleSignOut(e)}
          style={{ width: "100%", justifyContent: "flex-start" }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <H5>{t("logout")}</H5>
        </MenuItem>
      </Menu>
    </>
  );
}
