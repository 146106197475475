import {
  signupFormTemplatePowerforce,
  editOwnerTemplate,
} from "../../forms/signupFormTemplate";
import { API } from "../../utils/api";
import { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { TextField } from "@mui/material";
import { Container } from "@mui/material";
import { renderFormFields } from "../../utils/misc";
import { useFormik, Formik, Form } from "formik";
import { H1, H2, H4 } from "../../components/reusable/Heading";
import { Spinner } from "../../components/reusable/Spinner";
import { validationsFor } from "../../validations/validations";
import { Grid, Button, Typography } from "@material-ui/core";
import { Steps, StepsProvider, useSteps } from "react-step-builder";
import SnackbarAlert from "../../components/reusable/SnackbarAlert/index";
import QuestionTree from "../../components/QuestionTree/index";
import logo from "../../images/connect-logo.png";
import { LinearProgress } from "@material-ui/core";
import { Link, useSearchParams } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import { isMobile, isIE, isSafari } from "react-device-detect";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import ReactCountryFlag from "react-country-flag";
import useGeoLocation from "react-ipgeolocation";
import t from "../../i18n";

const Powerforce = () => {
  return (
    <>
      <StepsProvider>
        <SignupWizard />
      </StepsProvider>
    </>
  );
};

const SignupWizard = () => {
  const { next, prev, current, jump } = useSteps();
  // create a function that renders a progress bar from current step
  const renderProgressBar = () => {
    const steps = ["Signup", "Edit Profile", "Questionnaire"];
    const currentStep = steps[current - 1];
    const currentStepIndex = steps.indexOf(currentStep);
    const progress = (currentStepIndex / steps.length) * 100;
    return (
      <>
        {" "}
        <Box sx={{ width: "100%" }}>
          <LinearProgress variant="determinate" value={progress} />
          {/* step x of x */}
          <span
            style={{ float: "right", marginTop: "2px" }}
          >{`Step ${current} of ${steps.length}`}</span>
        </Box>
      </>
    );
  };

  const { sg } = window.location.search;

  // Component state
  const [searchParams, setSearchParams] = useSearchParams();
  const [businessIdParam, setBusinessIdParam] = useState(searchParams.get("b"));
  const [businessLocation, setBusinessLocation] = useState(null);
  const [segment, setSegment] = useState("");
  const [country, setCountry] = useState("gb");

  const [alert, setAlert] = useState(null);
  const [open, setOpen] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [companyId, setCompanyId] = useState('');
  const [vatNumber, setVatNumber] = useState('');


  const handleSetCompanyId = (countryCode) => {
    setCompanyId(597);
  }

  const [owner, setOwner] = useState({
    email: ``,
    first_name: "",
    last_name: "",
    password: "",
  });

  const [business, setBusiness] = useState({
    name: "",
    number: "",
    street: "",
    zip: "",
    city: "",
    segment: "",
    country_code: "",
    companyId: ""
  });

  // export this to misc.js
  const handleClose = () => {
    setOpen(false);
  };

  const setBusinessSegment = (businessSegment) => {
    setSegment(businessSegment);
  };

  const displayAlert = (alert) => (
    <>
      {" "}
      <SnackbarAlert
        handleClose={handleClose}
        display={open}
        variant={alert.variant}
        content={alert.message}
      ></SnackbarAlert>
    </>
  );
  // export this to misc.js

  const getLocationData = async () => {
    const locationData = await API.loadLocationForBusiness(businessIdParam);
    return locationData;
  };

  useEffect(async () => {
    if (businessIdParam) {
      const locationData = await getLocationData();

      if (locationData.data) {
        setBusinessLocation(locationData.data);
        setBusiness({
          number: locationData.data.number,
          street: locationData.data.street,
          zip: locationData.data.zip,
          city: locationData.data.city,
          country_code: locationData.data.country_code,
          name: locationData.data.business_name,
          companyId: companyId
        });
      }
    }
  }, []);

  const [questionnaireValues, setQuestionnaireValues] = useState([]);

  const handleOwner = async (values) => {
    setIsLoading(true);
    values.country_code = 'gb';
    values.terms_accepted = true;
    const res = await API.checkEmail({ spotter: values, is_connect: true });

    // save values.password to local storage
    localStorage.setItem("p", values.password);

    if (res.status === 200) {
      setOwner(values);
      next();
    } else if (res.status === 400) {
      // TODO: fix color, move to top-right, add handleClose
      setAlert({
        variant: "error",
        message: t("email_error"),
        open: true,
      });
      setOpen(true);
    } else if (res.errors) {
      if (res.errors.password) {
        setAlert({
          variant: "error",
          message: "Your password must be 6 characters or longer.",
          open: true,
        });

        setOpen(true);
      }
    }
    setIsLoading(false);
  };

  const handleOwnerRegistration = async (values) => {
    setIsLoading(true);
    values.country_code = 'gb'
    values.terms_accepted = true;
    const res = await API.signUpOwner({ spotter: values, is_connect: true });

    // save values.password to local storage
    localStorage.setItem("p", values.password);
    setOwner(values);

    if (res.status === 200) {
      setOwner(res.data);
    } else if (res.status === 400) {
      // TODO: fix color, move to top-right, add handleClose
      setAlert({
        variant: "error",
        message: t("email_error"),
        open: true,
      });
      setOpen(true);
    } else if (res.errors) {
      if (res.errors.password) {
        setAlert({
          variant: "error",
          message: "Your password must be 6 characters or longer.",
          open: true,
        });

        setOpen(true);
      }
    }
    setIsLoading(false);
  };

  const handleBusiness = async (values) => {
    setIsLoading(true);
    await handleOwnerRegistration(owner);

    console.log('companyId from handle business', companyId)

    let data = sg
      ? {
          business: {
            name: values.name,
            owner_email: owner.email,
            companyId: companyId,
            location: {
              street: values.street,
              number: values.number,
              zip: values.zip,
              city: values.city,
            },
            segment_group: sg,
            bic: values.bic,
            iban: values.iban,
            vat_number: vatNumber,
          },
        }
      : {
          business: {
            companyId: companyId,
            name: values.name,
            owner_email: owner.email,
            location: {
              street: values.street,
              number: values.number,
              zip: values.zip,
              city: values.city,
              bic: values.bic,
              iban: values.iban,
              vat_number: vatNumber,
            },
            segment: segment,
          },
        };
    // skip location creation on backend if location already exists
    if (businessLocation) {
      data["skip_location"] = true;
    }
    const res = await API.signUpBusiness(data);

    if (res.status === 200) {
      setBusiness(JSON.parse(res.data));
      next();
    } else if (res.errors) {
      setAlert({
        variant: "error",
        message: res.errors,
        open: true,
      });
      setOpen(true);
    }

    setIsLoading(false);
  };

  const addQuestionnaireToBusiness = () => {
    const data = {
      business_id: business.id,
      signup_questionnaire_data: questionnaireValues,
    };
    API.addQuestionnaireToBusiness(data);
  };

  const handleRegistration = async (e) => {
    e.preventDefault();
    addQuestionnaireToBusiness();
    // sign in
    if (!isMobile) {
      window.location.href = "/app_url";
    }

    if (isMobile) {
      window.location.href = "/app_url";
      // close the browser
    }

    localStorage.removeItem("p");
  };

  function handleQuestionnaire(values) {
    setQuestionnaireValues([...questionnaireValues, values]);
  }

  const validationSchemaSignup = validationsFor("BUSINESSPOWERFORCE");
  const validationSchemaOwner = validationsFor("OWNER");

  return (
    <div>
      <div style={{ marginTop: "10%" }}>
        {/* connect logo */}
        <img style={{ display: "block", margin: "70px auto" }} src={logo}></img>

        <Steps>
          <Step
            next={next}
            prev={prev}
            content={t("personal_information_text")}
            validations={validationSchemaOwner}
            template={editOwnerTemplate}
            handleStep={handleOwner}
            initialFormValues={owner}
            heading={t("registration")}
            subHeading={t("personal_information")}
            description={t("personal_information_description")}
            currentStep={current}
            isLoading={isLoading}
            setCountryCode={setCountry}
            countryCode={business.country_code}
            handleSetCompanyId={handleSetCompanyId}
          />
          <Step
            style={{ display: "flex" }}
            businessId={businessIdParam}
            next={next}
            prev={prev}
            content={t("personal_business_text")}
            validations={validationSchemaSignup}
            template={signupFormTemplatePowerforce}
            handleStep={handleBusiness}
            initialFormValues={business}
            heading={t("registration")}
            subHeading={t("personal_information_2")}
            description={t("business_information_description")}
            currentStep={current}
            isLoading={isLoading}
            setSegment={setBusinessSegment}
            setVatNumber={setVatNumber}
          />
          {/* <Questionnaire
              next={next}
              prev={prev}
              handleQuestionnaire={handleQuestionnaire}
            /> */}

          <Result
            prev={prev}
            business={business}
            owner={owner}
            questionnaireValues={questionnaireValues}
            handleRegistration={handleRegistration}
            translations={t}
          />
        </Steps>
        <br></br>
        <br></br>
        {renderProgressBar(current)}
        <br></br>

        {alert && displayAlert(alert)}
      </div>
    </div>
  );
};
export default Powerforce;

const Result = ({
  owner,
  business,
  prev,
  questionnaireValues,
  handleRegistration,
}) => {
  if (!business || !owner) return null;
  const location = business.locations[0];
  
  function humanizeString(str) {
    const words = str.split(" ");

    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    return capitalizedWords.join(" ");
  }

  return (
    <Container>
      <H1>{t("overview")}</H1>
      <Box m={5}></Box>
      <H2>{t("personal_information")}</H2>
      <Typography> {`${owner.first_name} ${owner.last_name}`} </Typography>
      <Typography> {owner.email} </Typography>
      <Box m={5}></Box>

      <H2>{t("personal_information_2")}</H2>

      <Typography> {humanizeString(business.name)} </Typography>
      <Typography> {`${location.street} ${location.number}`} </Typography>
      <Typography> {`${location.zip}, ${location.city}`} </Typography>
      <Typography> {t("bic")}: {business.owner.bic} </Typography>
      <Typography> {t("iban")}: {business.owner.iban} </Typography>
      <Typography> {t("vat_number")}: {business.owner.vat_number} </Typography>
      <Box m={5}></Box>
      <Box m={5}></Box>

      {questionnaireValues && questionnaireValues.length > 0 && (
        <H2>{t("questionnaire_responses")}</H2>
      )}

      {questionnaireValues &&
        questionnaireValues.length > 0 &&
        questionnaireValues.map((value) => (
          <>
            <h3> {value.q} </h3>
            <Typography> {value.a} </Typography>
          </>
        ))}

      <Button
        style={{ float: "right" }}
        color="primary"
        onClick={(e) => handleRegistration(e)}
      >
        {t("confirm")}
      </Button>
      <Button style={{ float: "right" }} color="primary" onClick={() => prev()}>
        {t("back")}
      </Button>
    </Container>
  );
};

const Step = ({
  prev,
  next,
  content,
  template,
  validations,
  initialFormValues,
  handleStep,
  heading,
  subHeading,
  businessId,
  currentStep,
  isLoading,
  setSegment,
  setCountryCode,
  setVatNumber,
  countryCode,
  handleSetCompanyId
}) => {
  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: validations,
    enableReinitialize: true,
    onSubmit: async (values) => {
      handleStep(values);
    },
  });

  const formTemplate = template(formik);

  // fetch business
  useEffect(() => {
    if (businessId) {
      API.loadBusiness(businessId).then((res) => {
        if (res.status === 200) {
          formik.setValues(JSON.parse(res.data));
        }
      });
    }
  }, [businessId]);
  const [checkboxValue, setCheckboxValue] = useState(true);

  const setChecked = (e) => {
    setCheckboxValue();
  };

  const renderTermsAndConditions = () => {
    return (
      <div style={{ display: "flex" }}>
        <Checkbox onChange={(e) => setChecked(e)} />
        <a
          target="_blank"
          href="https://smartspotterconnect.com/terms-and-conditions"
        >
          <Typography
            className="terms-and-conditions"
            style={{ marginTop: "9px" }}
          >
            {t("terms_and_conditions")}
          </Typography>
        </a>
        <br></br>
      </div>
    );
  };

  const [selected, setSelected] = useState('other');


  const renderSegmentsSelect = () => {
    const selectOptions = [
      "other",
      "snack_bar",
      "takeaway",
      "lunch_room",
      "sports_venue",
      "gas_station",
      "neighborhood_store",
      "restaurant",
      "eatery",
      "bar_or_cafe",
    ];

    const handleChange = (event) => {
      setSegment(event.target.value);
      setSelected(event.target.value);
    };

    return (
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel
            sx={{
              color: "grey",
              "&.Mui-focused": {
                color: "black",
              },
              marginBottom: "15px",
            }}
            id="demo-simple-select-label"
          >
            {t("select_segment")}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label={t("select_segment")}
            onChange={handleChange}
            value={selected}
            variant="outlined"
            sx={{
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#91BE23",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#91BE23",
              },
              marginBottom: "20px",
            }}
          >
            {selectOptions.map((selectOption) => (
              <MenuItem key={selectOption} value={selectOption}>
                {t(selectOption)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  };

  const GBPrefixedInput = () => {
    const [value, setValue] = useState("GB");
  
    const handleChange = (event) => {
      const inputValue = event.target.value;
  
      // Ensure the value always starts with "GB"
      if (inputValue.startsWith("GB")) {
        setValue(inputValue);
      }

      setVatNumber(event.target.value);
    };
  
    const handleKeyDown = (event) => {
      const { key, target } = event;
      const cursorPosition = target.selectionStart;
  
      // Prevent backspacing or deleting "GB"
      if (
        (key === "Backspace" || key === "Delete") &&
        cursorPosition <= 2 // Prevent changes before "GB"
      ) {
        event.preventDefault();
      }
    };
  
    return (
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel
            InputLabelProps={{
              sx: {
                color: "grey", // Default label color
                "&.Mui-focused": {
                  color: "black", // Change label color to black on focus
                },
              },
            }}
          >
          </InputLabel>
          <TextField
            id="gb-prefixed-input"
            variant="outlined"
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            label={t("vat_number")}
            InputLabelProps={{
              sx: {
                color: "grey", // Default label color
                "&.Mui-focused": {
                  color: "black", // Change label color to black on focus
                },
              },
            }}
            InputProps={{
              sx: {
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#91BE23", // Change border color to #91BE23 on focus
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#91BE23", // Change border color on hover
                },
              },
            }}
            sx={{
              marginBottom: "20px",
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#91BE23", // Border focus color
                },
              },
            }}
          />
        </FormControl>
      </Box>
    );
  };

  const location = useGeoLocation();
  const [selectedCountry, setSelectedCountry] = useState("");
  useEffect(() => {
    if (countryCode) {
      setSelectedCountry(countryCode);
      if (setCountryCode) {
        setCountryCode(countryCode);
        handleSetCompanyId(countryCode)
      }
    } else if (location.country) {
      setSelectedCountry(location.country.toLowerCase());
      if (setCountryCode) {
        setCountryCode(location.country.toLowerCase());
        handleSetCompanyId(location.country.toLowerCase())

      }
    } else {
      setSelectedCountry(window.navigator.language);

      if (setCountryCode) {
        setCountryCode(window.navigator.language);
        handleSetCompanyId(window.navigator.language)
      }
    }
  }, [location.isLoading]);



  const renderCountriesSelect = () => {
    const selectOptions = ["GB"]; // Use uppercase "GB" for the UK
  
    const handleChange = (event) => {
      const value = event.target.value;
      setCountryCode(value);
      setSelectedCountry(value);
      handleSetCompanyId(value); // Assuming handleSetCompanyId is defined elsewhere
    };
  
    return (
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel
            sx={{
              color: "grey",
              "&.Mui-focused": {
                color: "black",
              },
              marginBottom: "15px",
            }}
            id="demo-simple-select-label"
          >
            {t("select_country")}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label={t("select_country")}
            onChange={handleChange}
            value={selectedCountry}
            variant="outlined"
            sx={{
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#91BE23",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#91BE23",
              },
              marginBottom: "20px",
            }}
          >
            {selectOptions.map((selectOption) => (
              <MenuItem key={selectOption} value={selectOption}>
                <ReactCountryFlag
                  countryCode={selectOption}
                  svg
                  style={{ marginRight: "10px" }}
                />
                {t(selectOption.toLowerCase())} {/* Convert to lowercase to match translation keys */}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  };

  return (
    <>
      <Container container>
        <Grid style={{ display: "flex" }} item sx={12}>
          <H1 style={{}}>{heading}</H1>
        </Grid>
        <Box m={4}></Box>

        <Grid sx={12}></Grid>
        <Box m={2}></Box>

        <Grid>
          <Typography className="signup-explanation-text">{content}</Typography>
        </Grid>
        <Box m={2}></Box>

        <Grid>
          {" "}
          <Formik>
            <Form onSubmit={formik.handleSubmit} noValidate>
              {/* Render form fields */}
              {renderFormFields(formTemplate)} <Box m={2}></Box>{" "}
              {/* Render terms and conditions on step 1 of signup */}
              {currentStep === 2 && GBPrefixedInput()}
              {currentStep === 2 && renderSegmentsSelect()}
              {isLoading && <Spinner size={48}></Spinner>}
              <Button
                disabled={(currentStep === 1 && checkboxValue) || isLoading}
                style={{ float: "right" }}
                color="primary"
                type="submit"
                variant="contained"
              >
                {t("next")}
              </Button>
              {currentStep > 1 && (
                <Button
                  style={{ float: "right" }}
                  color="primary"
                  onClick={prev}
                >
                  {t("prev")}
                </Button>
              )}
            </Form>
          </Formik>
          {currentStep === 1 && renderTermsAndConditions()}
        </Grid>
      </Container>
    </>
  );
};

const Questionnaire = ({ next, handleQuestionnaire }) => {
  return (
    <>
      <Grid item sx={12}>
        <H1>{t("registration")}</H1>
      </Grid>
      <Box m={4}></Box>

      <Grid sx={12}>
        <H2>{t("signup_questionnaire")}</H2>
      </Grid>
      <Box m={2}></Box>
      <Grid>
        <Typography>{t("questionnaire_explanation")} </Typography>
      </Grid>
      <Box m={2}></Box>
      <QuestionTree next={next} handleQuestionnaire={handleQuestionnaire} />
    </>
  );
};
