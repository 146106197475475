import React from "react";
import PropTypes from "prop-types";
import { Switch } from "./Switch";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

export function Toggle(props) {
  const checkboxRef = React.useRef();
  const [on, setOn] = React.useState(props.defaultOn);
  const toggle = () => {
    setOn(!on);
    // Simulate a click on the DOM element to fire
    // its `onChange` handler.
    checkboxRef.current.click();
  };
  const isOnControlled = () => props.on !== undefined;
  const getOn = () => (isOnControlled() ? props.on : on);

  let styles = {
    display: "grid",
    justifyContent: "flex-end",
  };

  return (
    <div className="toggle" style={styles}>
      <VisuallyHidden>
        <input
          ref={checkboxRef}
          type="checkbox"
          defaultChecked={getOn()}
          onChange={(e) => props.onToggle(e.target.checked)}
        />
      </VisuallyHidden>
      <Switch
        on={on}
        aria-label="Toggle"
        aria-expanded={getOn()}
        tabIndex="0"
        {...props}
        onClick={toggle}
      />
    </div>
  );
}

export const visuallyHidden = css`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
`;

export const VisuallyHidden = styled.div`
  ${visuallyHidden};
`;

Toggle.propTypes = {
  defaultOn: PropTypes.bool,
  on: PropTypes.bool,
  onToggle: PropTypes.func,
};

Toggle.defaultProps = {
  defaultOn: false,
  onToggle: () => {},
};
