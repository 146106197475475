import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import translationEN from './locales/en/translation.json';
import translationNL from './locales/nl/translation.json';
import translationES from './locales/es/translation.json';
import translationDE from './locales/de/translation.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  es: {
    translation: translationES
  },
  nl: {
    translation: translationNL
  },
  de: {
    translation: translationDE
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(detector)
  .init({
    resources,
    // supported languages
    supportedLngs: ["en", "es", "nl"],
    //  'de'],
    //  locales in lowercase
    lowerCaseLng: true,
    fallbackLng: { 
        'es-co': ['es'],
        "es-es": ['es'],
        "co": ['es'],
        "nl-nl": ['nl'],
        "de-de": ['de'],
        'default': ['en']
    },
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n.t;