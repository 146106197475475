import { Alert, Grid } from "@mui/material";
import { useApi } from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";
import Rank from "../../components/reusable/Rank";
import Stats from "../../components/reusable/Stats";
import Graph from "../../components/reusable/Graph";
import { Login } from "../../components/Login/index";
import { Text } from "../../components/reusable/Text";
import Avatar from "../../components/reusable/Avatar";
import { H4 } from "../../components/reusable/Heading";
import { Spinner } from "../../icons/Spinner";
import t from '../../i18n';
import PaymentOptions from "../../components/PaymentOptions";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useEffect } from "react";

const Profile = () => {

  const {
    context: { hasCookie },
  } = useAuth();

  const showProfileIncompleteAlert = () => {
    return (
      <Alert severity="warning" color="info">
        {t("profile_incomplete_alert_1")}
        <span
          onClick={() =>
            navigate({
              pathname: "edit",
              search: createSearchParams({
                personal: "true",
              }).toString(),
            })
          }
          style={{ fontWeight: 600, cursor: "pointer" }}
        >
          here{" "}
        </span>
        {t("profile_incomplete_alert_2")}
      </Alert>
    );
  };

  const [spotterData] = useApi("loadProfile");
  const [businessData] = useApi("loadBusinessData");
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (spotterData) {
  //     if (!Cookies.get("lang")) Cookies.set("lang", spotterData.country_code);

  //     i18n.changeLanguage(spotterData.country_code);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (spotterData) {
  //     if (!spotterData.is_connect) {
  //       window.location.href = "https://spotters.smartspotter.com";
  //     }
  //   }
  // }, [spotterData]);

  if (!hasCookie) {
    return <Login />;
  }

  if (hasCookie && spotterData) {
    return (
      <>
        {" "}
        {/* Spotter Avatar */}
        <Grid
          style={{ justifyContent: "center", marginTop: "50px" }}
          alignItems="center"
          container
          direction="row"
          item
          xs={12}
          className="profile-wrapper"
        >
          {spotterData && <Avatar alt="S L" data={spotterData} />}
        </Grid>
        {/* Spotter Info */}
        <Grid>
          <Grid
            style={{ justifyContent: "center", marginBottom: "40px" }}
            alignItems="center"
            container
            direction="row"
            item
            xs={12}
          >
            {" "}
            <Grid>
              <H4 style={{ margin: "0px" }}>{spotterData.email}</H4>
              <Text>
                {`${spotterData.first_name} ${spotterData.last_name}`}{" "}
              </Text>
            </Grid>
          </Grid>

          {/* Spotter Ranking */}
          <Grid>
            <Rank
              position={spotterData.ranking}
              points={spotterData.points}
              balance={spotterData.balance}
            />
          </Grid>
          <br></br>
          {spotterData &&
            !spotterData.profile_complete &&
            showProfileIncompleteAlert()}

          {spotterData && spotterData.profile_complete && (
            <PaymentOptions data={spotterData} t={t} />
          )}
          <br></br>
          {businessData && (
            <>
              <Grid>
                <Stats
                  employees={businessData.business_stats.number_employees}
                  locations={businessData.business_stats.number_locations}
                  total={businessData.business_stats.total_spots_count}
                  monthly={businessData.business_stats.monthly_spots_count}
                  weekly={businessData.business_stats.weekly_spots_count}
                />
              </Grid>

              <br></br>

              <Grid style={{ marginBottom: "350px" }}>
                <Graph data={businessData.business_stats.spots_per_day} />
              </Grid>
            </>
          )}
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <Spinner
          style={{ marginLeft: "38%", marginTop: "25%" }}
          size={150}
        ></Spinner>
      </>
    );
  }
};

export default Profile;
