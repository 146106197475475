import { sharedProps } from "../utils/misc";
import { InputAdornment } from "@material-ui/core";
import StoreIcon from "@mui/icons-material/Store";
import SignpostIcon from "@mui/icons-material/Signpost";
import NumbersIcon from "@mui/icons-material/Numbers";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import EmailIcon from "@mui/icons-material/Email";
import BadgeIcon from "@mui/icons-material/Badge";
import PasswordIcon from "@mui/icons-material/Password";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import TagIcon from '@mui/icons-material/Tag';
import t from '../i18n';
const iconForField = (Icon) => {
  return {
    endAdornment: (
      <InputAdornment position="start">
        <Icon />
      </InputAdornment>
    ),
  };
};

export const signupFormTemplate = (formik) => {
  return {
    title: "Signup Form",
    Fields: [
      {
        name: "name",
        type: "text",
        title: "name",
        id: "name",
        label: t("company_name"),
        autoComplete: "Name",
        ...sharedProps,
        InputProps: iconForField(StoreIcon),
        value: formik.values.name,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.name),
        helperText: formik.errors.name,
      },

      {
        name: "street",
        type: "text",
        title: "street",
        id: "street",
        label: t("street"),
        autoComplete: "Street",
        InputProps: iconForField(SignpostIcon),
        ...sharedProps,
        value: formik.values.street,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.street),
        helperText: formik.errors.street,
      },
      {
        name: "number",
        type: "text",
        title: "number",
        id: "number",
        label: t("number"),
        autoComplete: "Number",
        ...sharedProps,
        InputProps: iconForField(NumbersIcon),
        value: formik.values.number,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.number),
        helperText: formik.errors.number,
      },
      {
        name: "city",
        type: "text",
        title: "city",
        id: "city",
        label: t("city"),
        autoComplete: "city",
        ...sharedProps,
        InputProps: iconForField(LocationCityIcon),
        value: formik.values.city,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.city),
        helperText: formik.errors.city,
      },
      {
        name: "zip",
        type: "text",
        title: "zip",
        id: "zip",
        label: t("zip"),
        autoComplete: "Zip",
        ...sharedProps,
        InputProps: iconForField(LocalShippingIcon),
        value: formik.values.zip,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.zip),
        helperText: formik.errors.zip,
      },
      {
        name: "bic",
        type: "text",
        title: "bic",
        id: "bic",
        label: t("bic"),
        autoComplete: "bic",
        InputProps: iconForField(AccountBalanceIcon),
        ...sharedProps,
        value: formik.values.bic,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.bic),
        helperText: formik.errors.bic,
      },
      {
        name: "iban",
        type: "text",
        title: "iban",
        id: "iban",
        label: t("iban"),
        autoComplete: "iban",
        InputProps: iconForField(AccountBalanceIcon),
        ...sharedProps,
        value: formik.values.iban,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.iban),
        helperText: formik.errors.iban,
      },
      {
        name: "vat_number",
        type: "text",
        title: "vat_number",
        id: "vat_number",
        label: t("vat_number"),
        autoComplete: "vat_number",
        InputProps: iconForField(TagIcon),
        ...sharedProps,
        value: formik.values.vat,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.vat_number),
        helperText: formik.errors.vat_number,
      },
    ],
  };
};

export const signupFormTemplatePowerforce = (formik) => {
  return {
    title: "Signup Form",
    Fields: [
      {
        name: "name",
        type: "text",
        title: "name",
        id: "name",
        label: t("company_name"),
        autoComplete: "Name",
        ...sharedProps,
        InputProps: iconForField(StoreIcon),
        value: formik.values.name,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.name),
        helperText: formik.errors.name,
      },

      {
        name: "street",
        type: "text",
        title: "street",
        id: "street",
        label: t("street"),
        autoComplete: "Street",
        InputProps: iconForField(SignpostIcon),
        ...sharedProps,
        value: formik.values.street,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.street),
        helperText: formik.errors.street,
      },
      {
        name: "number",
        type: "text",
        title: "number",
        id: "number",
        label: t("number"),
        autoComplete: "Number",
        ...sharedProps,
        InputProps: iconForField(NumbersIcon),
        value: formik.values.number,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.number),
        helperText: formik.errors.number,
      },
      {
        name: "city",
        type: "text",
        title: "city",
        id: "city",
        label: t("city"),
        autoComplete: "city",
        ...sharedProps,
        InputProps: iconForField(LocationCityIcon),
        value: formik.values.city,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.city),
        helperText: formik.errors.city,
      },
      {
        name: "zip",
        type: "text",
        title: "zip",
        id: "zip",
        label: t("zip"),
        autoComplete: "Zip",
        ...sharedProps,
        InputProps: iconForField(LocalShippingIcon),
        value: formik.values.zip,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.zip),
        helperText: formik.errors.zip,
      },
      {
        name: "bic",
        type: "text",
        title: "bic",
        id: "bic",
        label: t("bic"),
        autoComplete: "bic",
        InputProps: iconForField(AccountBalanceIcon),
        ...sharedProps,
        value: formik.values.bic,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.bic),
        helperText: formik.errors.bic,
      },
      {
        name: "iban",
        type: "text",
        title: "iban",
        id: "iban",
        label: t("iban"),
        autoComplete: "iban",
        InputProps: iconForField(AccountBalanceIcon),
        ...sharedProps,
        value: formik.values.iban,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.iban),
        helperText: formik.errors.iban,
      },
    ],
  };
};

export const editOwnerTemplate = (formik) => {
  return {
    title: "Edit Owner",
    Fields: [
      {
        name: "email",
        type: "text",
        title: "email",
        id: "email",
        label: t("email"),
        autoComplete: "email",
        ...sharedProps,
        InputProps: iconForField(EmailIcon),
        value: formik.values.email,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.email),
        helperText: formik.errors.email,
      },
      {
        name: "first_name",
        type: "text",
        title: "first_name",
        id: "first_name",
        label: t("first_name"),
        autoComplete: "first_name",
        InputProps: iconForField(BadgeIcon),
        ...sharedProps,
        value: formik.values.first_name,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.first_name),
        helperText: formik.errors.first_name,
      },
      {
        name: "last_name",
        type: "text",
        title: "last_name",
        id: "last_name",
        label: t("last_name"),
        autoComplete: "last_name",
        InputProps: iconForField(BadgeIcon),
        ...sharedProps,
        value: formik.values.last_name,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.last_name),
        helperText: formik.errors.last_name,
      },
      {
        name: "password",
        type: "password",
        title: "password",
        id: "password",
        label: t("password"),
        autoComplete: "password",
        InputProps: iconForField(PasswordIcon),
        ...sharedProps,
        value: formik.values.password,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.password),
        helperText: formik.errors.password,
      },
    ],
  };
};
