import React from "react";
import t from '../../../i18n';

const AppQrCodes = () => {
  return (
    <div style={divStyle}>
      <h3>{t("thank_you_for_registering")}</h3>
      <br></br>
      <h5>{t("download_apps_text")}</h5>
      <br></br>
      <br></br>

      <a href="https://apps.apple.com/gb/app/id468416419">
        <img
          style={imageStyle}
          src="https://www.smartspotter.com/application/files/2116/6418/1072/badge-apple.svg"
        ></img>
      </a>
      <a href="https://apps.apple.com/gb/app/id468416419">
        <img
          style={imageStyle}
          src="https://www.smartspotter.com/application/files/3316/6418/2454/badge-google.svg"
        ></img>
      </a>
    </div>
  );
};

const divStyle = {
  marginTop: "30%",
  width: "100%",
  textAlign: "center",
};

const imageStyle = {
  width: "40%",
  height: "40%",
  objectFit: "cover",
  objectPosition: "center",
  margin: "20px",
};

export default AppQrCodes;
