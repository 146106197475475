import { API } from "../../utils/api";
import { useApi } from "../../hooks/useApi";
import Button from "@material-ui/core/Button";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { renderFormFields } from "../../utils/misc";
import { Formik, Form, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { H1, H2 } from "../../components/reusable/Heading";
import { validationsFor } from "../../validations/validations";
import SnackbarAlert from "../../components/reusable/SnackbarAlert";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { simulateMouseClick } from "../../utils/misc";
import {
  editFormTemplate,
  editOwnerTemplate,
  addBusinessTemplate,
} from "../../forms/editFormTemplate";
import { billingInformationTemplate } from "../../forms/billingInformationTemplate";
import { LocationList } from "../../components/LocationList";
import Business from "../../components/Business/index";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import t from '../../i18n';
import { Typography } from "@material-ui/core";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const Edit = () => {
  const [open, setOpen] = React.useState(true);
  const [business] = useApi("loadBusiness");
  const [locations, setLocations] = useState([]);
  const [alert, setAlert] = useState(null);
  const [initialFormValues, setInitialFormValues] = useState({
    name: "",
    number: "",
    street: "",
    zip: "",
    city: "",
    email: "",
    first_name: "",
    last_name: "",
  });

  const [initialBillingFormValues, setInitialBillingFormValues] = useState({
    street: "",
    number: "",
    zip: "",
    city: "",
    iban: "",
    bic: "",
    vat_number: "",
  });

  const [initialAddBusinessFormValues, setInitialAddBusinessFormValues] =
    useState({
      name: "",
      street: "",
      number: "",
      zip: "",
      city: "",
      iban: "",
      bic: "",
    });

  const [showSpinner, setShowSpinner] = React.useState(false);

  const validationSchemaEdit = validationsFor("EDIT_PROFILE");
  const validationSchemaOwner = validationsFor("OWNER_EDIT");
  const validationBillingInformationSchema = validationsFor(
    "BILLING_INFORMATION"
  );
  const validationAddBusiness = validationsFor("BUSINESS");

  const [value, setValue] = useState(dayjs("2014-08-18T21:11:54"));

  useEffect(() => {
    if (business && business.owner) {
      console.log("business.owner", business.owner);
      setValue(dayjs(business.owner.birthdate));
    }
  }, [business]);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  // import router
  const router = useLocation();

  useEffect(() => {
    if (router.search) {
      const param = router.search.split("=")[0].replace("?", "");
      const element = document.getElementById(param);

      if (element) {
        simulateMouseClick(element);
      }
    }
  }, [router]);

  const [initialFormValuesOwner, setInitialFormValuesOwner] = useState({
    email: "",
    first_name: "",
    last_name: "",
    birthdate: "",
  });

  const formikAddBusiness = useFormik({
    initialValues: initialAddBusinessFormValues,
    validationSchema: validationAddBusiness,
    enableReinitialize: true,

    onSubmit: async (values) => {
      const body = {
        business: {
          name: values.name,
          location: {
            street: values.street,
            number: values.number,
            zip: values.zip,
            city: values.city,
          },
        },
      };

      const req = await API.addBusinessToSpotter(body);

      // redirect to edit page with router
      window.location.href = "/edit";

      if (req && req.status === 200) {
        const data = JSON.parse(req.data);
        setAlert({
          variant: "success",
          message: req.message,
          open: true,
        });
        setOpen(true);
      } else if (req.status === 400) {
        setAlert({
          variant: "error",
          message: req.message,
          open: true,
        });
      }

      setShowSpinner(false);
    },
  });

  const formikOwner = useFormik({
    initialValues: initialFormValuesOwner,
    validationSchema: validationSchemaOwner,
    enableReinitialize: true,

    onSubmit: async (values) => {
      const body = {
        business: {
          name: values.name,
          location: {
            street: values.street,
            number: values.number,
            zip: values.zip,
            city: values.city,
          },
          owner: {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            birthdate: value,
          },
        },
      };

      const req = await API.updateBusiness(body, business.id);

      if (req.status === 200) {
        const data = JSON.parse(req.data);
        setAlert({
          variant: "success",
          message: req.message,
          open: true,
        });
        setOpen(true);
      } else if (req.status === 400) {
        setAlert({
          variant: "error",
          message: req.message,
          open: true,
        });
      }

      setShowSpinner(false);
    },
  });

  useEffect(() => {
    if (business && business.locations) {
      setLocations(business.locations);
      setInitialFormValuesOwner({
        email: business.owner.email,
        first_name: business.owner.first_name,
        last_name: business.owner.last_name,
      });

      if (business.owner) {
        console.log(business.owner, "fghj");
        setInitialBillingFormValues({
          street: business.owner.street,
          number: business.owner.number,
          zip: business.owner.zip,
          city: business.owner.city,
          iban: business.owner.iban,
          bic: business.owner.bic,
          vat_number: business.owner.vat_number,
        });
      }
    }
  }, [business]);

  // Form submit
  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: validationSchemaEdit,
    enableReinitialize: true,

    onSubmit: async (values) => {
      const body = {
        business: {
          name: values.name,
          location: {
            street: values.street,
            number: values.number,
            zip: values.zip,
            city: values.city,
          },
          owner: {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
          },
        },
      };

      const req = await API.updateBusiness(body, business.id);

      if (req.status === 200) {
        const data = JSON.parse(req.data);
        setLocations(data);
        setAlert({
          variant: "success",
          message: req.message,
          open: true,
        });
        setOpen(true);
      } else if (req.status === 400) {
        setAlert({
          variant: "error",
          message: req.message,
          open: true,
        });
      }
      setShowSpinner(false);
    },
  });

  const billingFormik = useFormik({
    initialValues: initialBillingFormValues,
    validationSchema: validationBillingInformationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let data = values;
      data["business_id"] = business.id;

      const req = await API.updateBillingInformation(data);
      const billingData = JSON.parse(req.data);

      if (req.status === 200) {
        setAlert({
          variant: "success",
          message: req.message,
          open: true,
        });
        setOpen(true);
      } else {
        setAlert({
          variant: "error",
          message: req.message,
          open: true,
        });
      }
      setShowSpinner(false);

      // populate initial form values
      if (billingData) {
        setInitialBillingFormValues({
          billingData,
        });
      }
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setInitialFormValues({ name: " business.name " });
  }, [business]);

  const businessTemplate = editFormTemplate(formik);
  const ownerTemplate = editOwnerTemplate(formikOwner);
  const billingTemplate = billingInformationTemplate(billingFormik);

  const classes = useStyles();

  const displayAlert = (alert) => (
    <SnackbarAlert
      handleClose={handleClose}
      display={open}
      content={alert.message}
    ></SnackbarAlert>
  );

  if (business && business.error) {
    return (
      // create a form for business with the following fields (name, street, number, zip, city)
      <div>
        <Formik>
          <Form onSubmit={formikAddBusiness.handleSubmit} novalidate>
            <H2
              className={`${classes.fiftyrem} ${classes.heading}`}
              id="personal"
              style={{ marginTop: "2rem", marginBottom: "1rem" }}
            >
              {t("add_business")} <EditIcon className={classes.floatright} />
            </H2>

            <Typography>{t("add_business_text")}</Typography>
            <br></br>

            {alert && displayAlert(alert)}
            <div style={{ marginRight: "5%", marginLeft: "5%" }}>
              {renderFormFields(addBusinessTemplate(formikAddBusiness))}
            </div>
            <Button
              onClick={() => {
                setShowSpinner(true);
              }}
              type="submit"
              variant="contained"
              color="primary"
              className={`${classes.submit} ${classes.floatright} outlined-button`}
            >
              {t("save")}{" "}
              {showSpinner && (
                <CircularProgress
                  size={20}
                  style={{ color: "white", marginLeft: "12px" }}
                />
              )}{" "}
            </Button>
          </Form>
        </Formik>
      </div>
    );
  } else {
    return (
      <div>
        <H1>{t("business_profile")}</H1>

        <hr></hr>
        <SnackbarAlert></SnackbarAlert>

        {/* BILLING INFORMATION FORM */}
        <Formik>
          <Form onSubmit={billingFormik.handleSubmit} novalidate>
            <Accordion>
              <AccordionSummary
                className="acc-btn"
                expandIcon={
                  <ArrowForwardIosSharpIcon className={`${classes.mr15}`} />
                }
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <H2
                  className={`${classes.fiftyrem} ${classes.heading}`}
                  id="personal"
                >
                  {t("billing_information")}{" "}
                  <EditIcon className={classes.floatright} />
                </H2>
              </AccordionSummary>

              <AccordionDetails>
                {alert && displayAlert(alert)}
                <div style={{ marginRight: "5%", marginLeft: "5%" }}>
                  {renderFormFields(billingTemplate)}
                </div>
                <Button
                  onClick={() => {
                    setShowSpinner(true);
                  }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={`${classes.submit} ${classes.floatright} outlined-button`}
                >
                  {t("save")}{" "}
                  {showSpinner && (
                    <CircularProgress
                      size={20}
                      style={{ color: "white", marginLeft: "12px" }}
                    />
                  )}{" "}
                </Button>
              </AccordionDetails>
            </Accordion>{" "}
          </Form>
        </Formik>

        {/* PERSONAL INFORMATION FORM */}
        <Formik>
          <Form onSubmit={formikOwner.handleSubmit} noValidate>
            <Accordion>
              <AccordionSummary
                className="acc-btn"
                expandIcon={
                  <ArrowForwardIosSharpIcon className={`${classes.mr15}`} />
                }
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <H2
                  className={`${classes.fiftyrem} ${classes.heading}`}
                  id="personal"
                >
                  {t("personal_information")}{" "}
                  <EditIcon className={classes.floatright} />
                </H2>
              </AccordionSummary>
              <AccordionDetails>
                {alert && displayAlert(alert)}
                <div style={{ marginRight: "5%", marginLeft: "5%" }}>
                  {renderFormFields(ownerTemplate)}
                </div>
                <Button
                  onClick={() => {
                    setShowSpinner(true);
                  }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={`${classes.submit} ${classes.floatright} outlined-button`}
                >
                  {t("save")}{" "}
                  {showSpinner && (
                    <CircularProgress
                      size={20}
                      style={{ color: "white", marginLeft: "12px" }}
                    />
                  )}{" "}
                </Button>
              </AccordionDetails>
            </Accordion>{" "}
            {/* LOCATIONS FORM */}
            <Accordion>
              <AccordionSummary
                className="acc-btn"
                expandIcon={
                  <ArrowForwardIosSharpIcon className={classes.mr15} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <H2 className={`${classes.fiftyrem}`} id="business">
                  {t("locations")} <EditIcon className={classes.floatright} />
                </H2>
              </AccordionSummary>
              <AccordionDetails>
                {alert && displayAlert(alert)}
                <div style={{ marginRight: "5%", marginLeft: "5%" }}>
                  {renderFormFields(businessTemplate)}
                </div>
                {business && business.locations && (
                  <LocationList
                    locations={locations}
                    businessId={business.id}
                    setLocationsData={setLocations}
                  ></LocationList>
                )}
              </AccordionDetails>
            </Accordion>{" "}
            <Accordion>
              <AccordionSummary
                className="acc-btn"
                expandIcon={
                  <ArrowForwardIosSharpIcon className={`${classes.mr15}`} />
                }
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <H2
                  className={`${classes.fiftyrem} #{classes.heading}`}
                  id="employee"
                >
                  {t("employees")} <EditIcon className={classes.floatright} />
                </H2>
              </AccordionSummary>
              <AccordionDetails>{<Business />}</AccordionDetails>
            </Accordion>
          </Form>
        </Formik>
        <div style={{ marginBottom: "1000px" }}></div>
      </div>
    );
  }
};

export default Edit;

// Styles
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  heading: { width: "50rem", float: "left" },
  fiftyrem: { width: "100%" },
  floatright: { float: "right" },
  floatleft: { float: "left" },
  ml15: { marginLeft: "15px" },
}));
