import "./index.css";

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./hooks/useAuth";
import { Desktop as Navbar } from "../src/components/Navbar/Desktop/index";
import { BaseStyles } from "./components/reusable/BaseStyles";
import AppQrCodes from "../src/components/reusable/AppQrCodes/index";

// Pages component containers
import Edit from "./pages/edit/index";
import Profile from "./pages/profile/index";
import Invoices from "./pages/invoices/index";
import Signup from "./pages/signup/index";
import Lebara from "./pages/signup/lebara";
import Powerforce from "./pages/signup/powerforce";

import Cookies from "js-cookie";
import AppUrl from "./pages/app_url";

// get cookies
const token = Cookies.get("spToken");

// pages go here
function App() {
  return (
    <div className="App">
      <div className="container">
        <AuthProvider>
          <BaseStyles />
          <BrowserRouter>
            {window.location.pathname !== "/app_url" && token && (
              <Navbar></Navbar>
            )}

            <Routes>
              <Route path="/" element={<Profile />} />
              <Route path="edit" element={<Edit />} />
              <Route path="invoices" element={<Invoices />} />
              <Route path="signup" element={<Signup />} />
              <Route path="signup/lebara" element={<Lebara />} />
              <Route path="signup/powerforce" element={<Powerforce />} />
              <Route path="app_links" element={<AppQrCodes />} />
              <Route path="app_url" element={<AppUrl />} />
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </div>
    </div>
  );
}

export default App;
