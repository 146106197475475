import React, { useState } from "react";
import { resetPasswordFormTemplate } from "../../forms/resetPasswordFormTemplate";
import { Formik, Form, useFormik } from "formik";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { H2 } from "../reusable/Heading/index";
import { Image } from "./styles";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import { API } from "../../utils/api";
import logo from "../../images/connect-logo.png";
import { renderFormFields } from "../../utils/misc";
import t from '../../i18n';

import { Alert } from "../../components/reusable/Alert/index";

export const ForgotPassword = ({ toggleForgotPassword }) => {
  
  const [resetPassword, setResetPassword] = useState(false);
  const toggleResetPassword = () => setResetPassword(!resetPassword);
  const [email, setEmail] = useState("");

  const initialFormValues = {
    email: "",
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    onSubmit: async (values, { setSubmitting }) => {
      const res = await handleSubmit({ spotter: values });
      if (!res) {
        formik.errors.email = t("incorrect_email");
      }
    },
  });

  const template = resetPasswordFormTemplate(formik);

  const handleSubmit = async (values) => {
    try {
      const data = await API.resetPassword(values);
      if (data.status === "success") {
        setEmail(values.spotter.email);
        toggleResetPassword();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const classes = useStyles();

  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Grid>
            <Image src={logo} alt="smslogo" />
          </Grid>
          {resetPassword ? (
            <>
              <Alert variant="success">
                {t("mail_instructions_sent")}
                <strong>{email}</strong>
              </Alert>
            </>
          ) : (
            <div>
              <H2> {t("new_password")}</H2>
              <Formik>
                <Form className={classes.form} onSubmit={formik.handleSubmit}>
                  {renderFormFields(template)}

                  <Button
                    type="submit"
                    fullWidth={true}
                    variant="contained"
                    color="primary"
                    style={{ background: "#172C53", color: "white" }}
                    className={classes.submit}
                    onSubmit={handleSubmit}
                  >
                    {t("recover_password")}
                  </Button>
                </Form>
              </Formik>
            </div>
          )}
          <Button type="button" onClick={() => toggleForgotPassword()}>
            {" "}
            {t("back")}{" "}
          </Button>
        </div>
      </Container>
    </div>
  );
};

// Styles
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
