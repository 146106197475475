import { sharedProps } from "../utils/misc";
import t from '../i18n';

export const signInFormTemplate = (formik) => {
  return {
    title: "Sign In Form",
    Fields: [
      {
        name: "email",
        type: "text",
        title: "email",
        id: "email",
        label: t("email"),
        autoComplete: "email",
        ...sharedProps,
        value: formik.values.email,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.email),
        helperText: formik.errors.email,
      },
      {
        name: "password",
        type: "password",
        title: "Password",
        id: "password",
        label: t("password"),
        autoComplete: "password",
        ...sharedProps,
        value: formik.values.password,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.password),
        helperText: formik.errors.password,
      },
    ],
  };
};
