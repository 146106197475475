import { sharedProps } from "../utils/misc";
import t from '../i18n';

export const resetPasswordFormTemplate = (formik) => {
  return {
    title: "Reset Password Form",
    Fields: [
      {
        name: "email",
        type: "text",
        title: "email",
        id: "email",
        label: t("email"),
        autoComplete: "email",
        ...sharedProps,
        value: formik.values.email,
        onChange: formik.handleChange,
        error: formik.touched.email && Boolean(formik.errors.email),
        helperText: formik.touched.email && formik.errors.email,
      },
    ],
  };
};
