import styled from "@emotion/styled";
import { color } from "../../../theme";
import { H3 } from "../Heading";
import { Card } from "@mui/material";
import t from '../../../i18n';


const Rank = ({ position, points, balance }) => {
  return (
    <>
      <Card style={cardStyle}>
        <RankRow>
          {points >= 100 && (
            <RankColumn>
              <H3>{t("ranking")}</H3>
            </RankColumn>
          )}
          <RankColumn>
            <H3>{t("points")} </H3>
          </RankColumn>
          <RankColumn>
            <H3>{t("balance")}</H3>
          </RankColumn>
        </RankRow>
        <RankRow>
          {points >= 100 && (
            <RankColumn>
              <RankPosition>
                <H3>{position}</H3>
              </RankPosition>
            </RankColumn>
          )}
          <RankColumn>
            <RankPoints>
              <H3>{points}</H3>
            </RankPoints>
          </RankColumn>
          <RankColumn>
            <RankPoints>
              <H3>{balance}</H3>
            </RankPoints>
          </RankColumn>
        </RankRow>
      </Card>
    </>
  );
};

export default Rank;

const cardStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  flexWrap: "wrap",
  borderRadius: "4px",
  background: `${color.stardust}`,
  paddingBottom: "1rem",
  paddingTop: "1rem",
};

// Rank styles
export const RankContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 4px;
  background: ${color.stardust};
  padding-bottom: 1rem;
  padding-top: 1rem;
`;
export const RankPosition = styled.div`
  text-align: center;
  color: ${color.spaceMedium};
`;
export const RankPoints = styled.div`
  text-align: center;
  color: ${color.spaceMedium};
  margin-bottom: 10px;
`;
export const RankRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;
export const RankColumn = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;
