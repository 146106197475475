import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

export const sharedProps = {
  variant: "outlined",
  margin: "normal",
  required: true,
  autoFocus: true,
  fullWidth: true,
};

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      textDecoration: "none",
      color: "inherit",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#91BE23",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#91BE23",
      },
    },
  },
})(TextField);

export const renderFormFields = (template) =>
  template.Fields.map((field, i) => <CssTextField {...field} key={i} />);

export const renderLocationFormFields = (template, i) => {
  return (
    <>
      <h5 style={{ marginTop: "6%" }}>Location {i + 1}</h5>
      {template.Fields.map((field, i) => (
        <TextField {...field} key={i} />
      ))}
    </>
  );
};

export const truncateString = (string, maxLength, seperator = "...") => {
  if (string.length > maxLength) {
    return string.slice(0, maxLength - seperator.length) + seperator;
  }
  return string;
};

export const dateFormat = (locale, date, style = "full") => {
  const originalDate = new Date(date);
  return Intl.DateTimeFormat(locale, {
    dateStyle: style,
  }).format(originalDate);
};

export function simulateMouseClick(element) {
  const mouseClickEvents = ["mousedown", "click", "mouseup"];

  mouseClickEvents.forEach((mouseEventType) =>
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      })
    )
  );
}
