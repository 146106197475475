import React, { useState, useEffect } from "react";
import { baseUrl } from "../../utils/api";
import { Toggle } from "./Toggle";
import styled from "@emotion/styled";
import SnackbarAlert from "../reusable/SnackbarAlert";

const PaymentOptions = ({ t, data }) => {
  const [paymentToggle, setPaymentToggle] = useState(null);
  const [automaticToggle, setAutomaticToggle] = useState(null);
  const [weeklyPayment, setWeeklyPayment] = useState(false);
  const [updateError, setUpdateError] = useState("");
  const [res, setRes] = useState({});
  const [alert, setAlert] = useState(false);

  const displayAlert = (alert) => (
    <SnackbarAlert
      //handleClose={handleClose}
      display={alert.open}
      content={alert.message}
    ></SnackbarAlert>
  );

  useEffect(() => {
    if (data) {
      setPaymentToggle(data.request_payment);
      setAutomaticToggle(data.auto_payments);
    }
  }, [data]);

  const requestPayment = (on) => {
    fetch(`${baseUrl}v1/spotters/update`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Access-Control-Allow-Origin": baseUrl,
        "Acces-Control-Allow-Credentials": "true",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        spotterPayment: {
          request_payment: on,
          auto_payments: !on ? false : !on,
        },
      }),
    })
      .then((response) => {
        if (response.ok) {
          setRes({
            status: `$"REQ PAYMENT"`,
          });
          setAlert({
            variant: "error",
            message: "Payment request sent",
            open: true,
          });
          setTimeout(() => {
            setAlert(false);
          }, 2000);
          const x = !paymentToggle;
          setPaymentToggle(x);
          if (x) {
            setAutomaticToggle(false);
          }
          return response.json();
        } else {
          setAlert({
            variant: "error",
            message: "Something went wrong",
            open: true,
          });
          setTimeout(() => {
            setAlert(false);
          }, 2000);
        }
        throw response;
      })
      .catch((err) => setUpdateError(err));
  };

  const requestAutomaticPayment = (on) => {
    console.log("autoPayment", on);

    fetch(`${baseUrl}v1/spotters/update`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Access-Control-Allow-Origin": baseUrl,
        "Acces-Control-Allow-Credentials": "true",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        spotterPayment: {
          auto_payments: on,
          request_payment: false,
        },
      }),
    })
      .then((response) => {
        if (response.ok) {
          setRes({
            status: `AP ENABLED`,
          });
          setAlert({
            variant: "error",
            message: "Payment request sent",
            open: true,
          });
          setTimeout(() => {
            setAlert(false);
          }, 2000);

          setAutomaticToggle(!automaticToggle);
          if (!automaticToggle) {
            setPaymentToggle(false);
          }

          return response.json();
        } else {
          setAlert({
            variant: "error",
            message: "Something went wrong",
            open: true,
          });
          setTimeout(() => {
            setAlert(false);
          }, 2000);
        }

        throw response;
      })
      .catch((err) => setUpdateError(err));
  };

  return (
    <div style={{ marginTop: "50px" }}>
      {alert && displayAlert(alert)}
      <Item>
        <RequestPayment>
          <h3>{t("automatic_payment")}</h3>
          <Toggle
            disabled={data.balance < 0.51 ? true : false}
            onToggle={(on) => {
              requestAutomaticPayment(on);
            }}
            defaultOn={data.auto_payments}
            on={automaticToggle}
          >
            {" "}
          </Toggle>
          <PaymentInfo> {t("automatic_payment_description")} </PaymentInfo>
        </RequestPayment>
      </Item>
      <br></br>
      <Item>
        <RequestPayment>
          <h3>{t("request_payment")}</h3>
          <Toggle
            disabled={data.balance < 0.51 ? true : false}
            onToggle={(on) => {
              requestPayment(on);
            }}
            defaultOn={data.request_payment}
            on={paymentToggle}
          >
            {" "}
          </Toggle>
          <PaymentInfo> {t("request_payment_description")} </PaymentInfo>
        </RequestPayment>
      </Item>
    </div>
  );
};

export default PaymentOptions;
export const RequestPayment = styled.div`
  display: grid;
  grid-template-columns: 1fr 30%;
  justify-content: center;
  align-items: center;
`;
export const Item = styled.div`
  display: grid;
  width: 100%;
  justify-content: ${(props) => props.alignment};
  align-items: ${(props) => props.alignment};
`;

export const AutoPayments = styled.div`
  display: grid;
  grid-template-columns: 1fr 30%;
  justify-content: center;
  align-items: center;
`;

export const PaymentInfo = styled.p`
  color: grey;
  font-size: 13px;
`;
