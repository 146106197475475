import { sharedProps } from "../utils/misc";
import { InputAdornment } from "@material-ui/core";
import SignpostIcon from "@mui/icons-material/Signpost";
import NumbersIcon from "@mui/icons-material/Numbers";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import EmailIcon from "@mui/icons-material/Email";
import BadgeIcon from "@mui/icons-material/Badge";
import t from '../i18n';

const iconForField = (Icon) => {
  return {
    endAdornment: (
      <InputAdornment position="start">
        <Icon />
      </InputAdornment>
    ),
  };
};

export const billingInformationTemplate = (formik) => {
  return {
    title: "Billing information",
    Fields: [
      {
        name: "street",
        type: "text",
        title: "street",
        id: "street",
        label: t("street"),
        autoComplete: "street",
        InputProps: iconForField(SignpostIcon),
        ...sharedProps,
        value: formik.values.street,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.street),
        helperText: formik.errors.street,
      },
      {
        name: "number",
        type: "text",
        title: "number",
        id: "number",
        label: t("number"),
        autoComplete: "number",
        InputProps: iconForField(NumbersIcon),
        ...sharedProps,
        value: formik.values.number,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.number),
        helperText: formik.errors.number,
      },
      {
        name: "zip",
        type: "text",
        title: "zip",
        id: "zip",
        label: t("zip"),
        autoComplete: "zip",
        InputProps: iconForField(LocationCityIcon),
        ...sharedProps,
        value: formik.values.zip,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.zip),
        helperText: formik.errors.zip,
      },
      {
        name: "city",
        type: "text",
        title: "city",
        id: "city",
        label: t("city"),
        autoComplete: "city",
        InputProps: iconForField(LocationCityIcon),
        ...sharedProps,
        value: formik.values.city,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.city),
        helperText: formik.errors.city,
      },
      {
        name: "iban",
        type: "text",
        title: "iban",
        id: "iban",
        label: t("iban"),
        autoComplete: "iban",
        InputProps: iconForField(BadgeIcon),
        ...sharedProps,
        value: formik.values.iban,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.iban),
        helperText: formik.errors.iban,
      },
      {
        name: "bic",
        type: "text",
        title: "bic",
        id: "bic",
        label: t("bic"),
        autoComplete: "bic",
        InputProps: iconForField(BadgeIcon),
        ...sharedProps,
        value: formik.values.bic,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.bic),
        helperText: formik.errors.bic,
      },
      {
        name: "vat_number",
        type: "text",
        title: "VAT Number",
        id: "vat_number",
        label: "VAT Number",
        autoComplete: "vat_number",
        InputProps: iconForField(BadgeIcon),
        ...sharedProps,
        value: formik.values.vat_number,
        onChange: formik.handleChange,
        error: Boolean(formik.errors.vat_number),
        helperText: formik.errors.vat_number,
      },
    ],
  };
};
